import settingHandler from "@/services/Settings";

export default {
    // eslint-disable-next-line no-unused-vars
    async getSetting({commit, dispatch}, payload) {
        return settingHandler.get(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async storeSettings({commit, dispatch}, payload) {
        return settingHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateSettings({commit, dispatch}, payload) {
        return settingHandler.update(payload);
    }
}
