import postHandler from "@/services/Post";

export default {
    // eslint-disable-next-line no-unused-vars
    async getPost({commit, dispatch}, payload) {
        return postHandler.get(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async getPostSlug({commit, dispatch}, payload) {
        return postHandler.slug(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async storePost({commit, dispatch}, payload) {
        return postHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updatePost({commit, dispatch}, payload) {
        return postHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async removeImage({commit, dispatch}, payload) {
        return postHandler.removeImage(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async postRemove({commit, dispatch}, payload) {
        return postHandler.remove(payload);
    }
}
