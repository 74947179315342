<template>
  <div>
    <v-card :light="!$vuetify.theme.dark" class="ma-2" color="#fafafa" elevation="0">
      <v-card-text>
        <router-link to="/">
          <v-img
              :src="logo"
              max-height="100"
          />
        </router-link>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-list rounded>
      <template v-for="(item, key) in menus">
        <v-list-group
            v-if="item.subMenuItems"
            :prepend-icon="item.icon"
            color="#fafafa"
            :key="key"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
              v-for="(subMenu, i) in item.subMenuItems"
              :key="i"
              :to="subMenu.path"
              color="#fafafa"
              router
              link
              exact
          >
            <v-list-item-icon>
              <!--eslint-disable-next-line-->
              <v-icon v-text="subMenu.icon"></v-icon>
            </v-list-item-icon>
            <!--eslint-disable-next-line-->
            <v-list-item-title v-text="subMenu.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
            v-else
            :to="item.path"
            color="#fafafa"
            router
            link
            exact
            :key="key"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "AppSidebarNav",
  data() {
    return {
      menus: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard', path: '/'},
        {title: 'Home', icon: 'mdi-home', path: '/home'},
        {title: 'About Prism', icon: 'mdi-briefcase', path: '/about-us'},
        {title: 'About CEO', icon: 'mdi-arrow-collapse-all', path: '/about-ceo'},
        {title: 'Testimonial', icon: 'mdi-book-open-page-variant', path: '/testimonial'},
        {
          title: 'Service',
          icon: 'mdi-layers',
          subMenuItems: [
            {title: 'Service Page', icon: 'mdi-layers', path: '/service-page'},
            {title: 'Service List', icon: 'mdi-format-list-bulleted', path: '/services'},
          ]
        },
        {
          title: 'Resources',
          icon: 'mdi-basket',
          subMenuItems: [
            {title: 'Resource Page', icon: 'mdi-basket-fill', path: '/resource-page'},
            {title: 'Books', icon: 'mdi-book-open-variant', path: '/books'},
            {title: 'Videos', icon: 'mdi-video', path: '/videos'},
            {title: 'IRS & Govt. Links', icon: 'mdi-link', path: '/irs-links'},
            {title: 'Federal Tax Links', icon: 'mdi-link', path: '/federal-tax-links'},
            {title: 'Financial Terms/Glossary', icon: 'mdi-link', path: '/financial-terms'},
            {title: 'Articles', icon: 'mdi-link', path: '/articles'},
            {title: 'Vocabulary', icon: 'mdi-link', path: '/vocabulary'},
          ]
        },
        {title: 'Contact', icon: 'mdi-contacts', path: '/contact-us'},
      ],

      logo: require('@/assets/img/logo.png')
    }
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  .company-logo-panel {
    width: 100%;
    text-align: center;
    transition: transform .3s ease-in-out, width .3s ease-in-out;

    .image {
      img {
        width: 100%;
        max-width: 100px;
        height: auto;
      }
    }

    .company-name {
      padding: 10px 5px 0px;
      color: #616161 !important;
    }
  }
}
</style>
