import AppHeader from "@/layouts/AppHeader";
import AppFooter from "@/layouts/AppFooter";

const routes = [
    {
        path: '/login',
        name: 'Login',
        components: {
            default: () => import('../views/Login')
        },
        meta: {guestOnly: true}
    },
    {
        path: '/',
        name: 'Dashboard',
        components: {
            header: AppHeader,
            default: () => import('../views/Dashboard'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/user',
        name: 'Settings',
        components: {
            header: AppHeader,
            default: () => import('../views/Settings'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/home',
        name: 'Home',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Home'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        components: {
            header: AppHeader,
            default: () => import('../views/front/AboutUs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/about-ceo',
        name: 'AboutCEO',
        components: {
            header: AppHeader,
            default: () => import('../views/front/AboutCEO'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Testimonial'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/service-page',
        name: 'ServicePage',
        components: {
            header: AppHeader,
            default: () => import('../views/front/ServicePage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/services',
        name: 'Services',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Service'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/resource-page',
        name: 'ResourcePage',
        components: {
            header: AppHeader,
            default: () => import('../views/front/ResourcePage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/books',
        name: 'Books',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Books'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/videos',
        name: 'Videos',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Videos'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/irs-links',
        name: 'IrsLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/front/IRSLinkPage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/federal-tax-links',
        name: 'FederalTaxLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/front/FederalTaxLinks'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/financial-terms',
        name: 'FinancialTerms',
        components: {
            header: AppHeader,
            default: () => import('../views/front/FinancialTerms'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/articles',
        name: 'Articles',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Articles'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/vocabulary',
        name: 'Vocabulary',
        components: {
            header: AppHeader,
            default: () => import('../views/front/Vocabulary'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        components: {
            header: AppHeader,
            default: () => import('../views/front/ContactUs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            header: AppHeader,
            default: () => import('../views/Profile.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
];

export default routes;
