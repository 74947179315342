import Api from "./Api";
const get = async (payload) => {
    const path = '/api/post/get';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint: endpoint
    })
}

const slug = async (data) => {
    return Api.postRequest({
        endpoint: '/api/post/slug',
        data: data
    })
}

const store = async (data) => {
    return Api.postRequest({
        endpoint: '/api/post/store',
        data: data
    })
}

const update = async (data) => {
    return Api.postRequest({
        endpoint: '/api/post/update',
        data: data
    })
}

const removeImage = async (data) => {
    return Api.postRequest({
        endpoint: '/api/post/remove-image',
        data: data
    })
}

const remove = async (data) => {
    return Api.deleteRequest({
        endpoint: `/api/post/delete/${data.id}`
    })
}

export default {
    get,
    slug,
    store,
    update,
    removeImage,
    remove
};
