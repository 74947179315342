import testimonialHandler from "@/services/Testimonial";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadTestimonials({commit, dispatch}, payload) {
        const {data} = await testimonialHandler.all(payload);

        commit('SET_TESTIMONIALS', data);
    }
}
