import videoHandler from "@/services/Video";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadVideos({commit, dispatch}, payload) {
        const {data} = await videoHandler.all(payload);

        commit('SET_VIDEOS', data);
    }
}
