import serviceHandler from "@/services/Service";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadServices({commit, dispatch}, payload) {
        const {data} = await serviceHandler.all(payload);

        commit('SET_SERVICES', data);
    }
}
