import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import settings from "./settings";
import user from "./user";
import post from "./post";
import testimonial from "./testimonial";
import service from "./service";
import book from "./book";
import video from "./video";

export default new Vuex.Store({
  modules: {
    user,
    settings,
    post,
    testimonial,
    service,
    book,
    video
  },
  state: {
    snackBar: {},
    user: null,
    showLoading: false,
  },
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload
    },
    SET_LOADING: function (state, payload) {
      state.showLoading = payload
    },
    SET_USER: function (state, payload) {
      state.user = payload
    }
  }
})
