import Api from "./Api";
const all = async (payload) => {
    const path = '/api/videos';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint: endpoint
    })
}

export default {
    all
};
