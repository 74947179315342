<template>
  <v-app>
    <v-main>
      <router-view name="header"/>
      <router-view/>
      <router-view name="footer"/>
      <snackbar-component/>
    </v-main>
  </v-app>
</template>

<style lang="scss">
</style>
<script>
import SnackbarComponent from "@/components/SnackbarComponent";
export default {
  metaInfo: {
    title: 'Prism Tax Strategies Inc.',
    titleTemplate: 'Website control panel | %s',
    meta: [
      { name: 'description', content: 'Salima Daredia is the CEO of Prism Tax Strategies, which she founded in 2020. Salima has 20+ years of experience as a certified public accountant.' },
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
     SnackbarComponent
  }
}
</script>