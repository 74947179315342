import bookHandler from "@/services/Book";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadBooks({commit, dispatch}, payload) {
        const {data} = await bookHandler.all(payload);

        commit('SET_BOOKS', data);
    }
}
