import Api from "./Api";
const get = async () => {
    return Api.getRequest({
        endpoint: '/api/settings/get'
    })
}

const store = async (data) => {
    return Api.postRequest({
        endpoint: '/api/settings/store',
        data: data
    })
}

const update = async (payload) => {
    return Api.postRequest({
        endpoint: '/api/settings/update',
        method: 'put',
        data: payload,
    })
}

export default {
    get,
    store,
    update
};
